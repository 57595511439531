import React, { useState } from "react";
import { Box, Button, Checkbox, Collapse, ListItemText, Typography, IconButton, Slide } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { DepartmentMaster, SiteType } from "../../../types";

interface FilterMenuProps {
    sites: SiteType[];
    departments: DepartmentMaster[];
    applyFilters: (selectedSites: SiteType[], selectedDepartments: DepartmentMaster[]) => void;
    clearFilters: () => void;
    onClose: () => void;
}

const MobileFilterComponent: React.FC<FilterMenuProps> = ({ sites, departments, applyFilters, clearFilters, onClose }) => {
    const [currentMenu, setCurrentMenu] = useState<"main" | "sites" | "departments">("main");
    const [tempSelectedSites, setTempSelectedSites] = useState<SiteType[]>([]);
    const [tempSelectedDepartments, setTempSelectedDepartments] = useState<DepartmentMaster[]>([]);

    const handleBack = () => setCurrentMenu("main");

    const handleApplyFilters = () => {
        applyFilters(tempSelectedSites, tempSelectedDepartments);
        onClose();
    };

    const handleClearFilters = () => {
        setTempSelectedSites([]);
        setTempSelectedDepartments([]);
        clearFilters();
        onClose();
    };

    const toggleSelection = <T extends object>(item: T, selectedItems: T[], setSelectedItems: React.Dispatch<React.SetStateAction<T[]>>) => {
        setSelectedItems((prev) =>
            prev.some((i) => JSON.stringify(i) === JSON.stringify(item)) ? prev.filter((i) => JSON.stringify(i) !== JSON.stringify(item)) : [...prev, item]
        );
    };

    return (
        <Slide direction="left" in={true} mountOnEnter unmountOnExit>
            <Box sx={{ maxWidth: 400, p: 2 }}>
                {/* Main Menu */}
                <Collapse in={currentMenu === "main"} unmountOnExit>
                    <Typography variant="h6" fontWeight="bold" sx={{ mb: 2 }}>
                        Filters
                    </Typography>
                    <Button variant="outlined" fullWidth sx={{ mb: 1 }} onClick={() => setCurrentMenu("sites")}>
                        Sites
                    </Button>
                    <Button variant="outlined" fullWidth sx={{ mb: 1 }} onClick={() => setCurrentMenu("departments")}>
                        Departments
                    </Button>
                </Collapse>

                {/* Sites Menu */}
                <Collapse in={currentMenu === "sites"} unmountOnExit>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                        <IconButton onClick={handleBack}>
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography variant="h6" fontWeight="bold" sx={{ ml: 1 }}>
                            Sites
                        </Typography>
                    </Box>
                    {sites.map((site) => (
                        <Box key={site.us_user_site_profile_id} sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                            <Checkbox
                                checked={tempSelectedSites.some((selectedSite) => JSON.stringify(selectedSite) === JSON.stringify(site))}
                                onChange={() => toggleSelection(site, tempSelectedSites, setTempSelectedSites)}
                            />
                            <ListItemText primary={site.user_site_name || "Unnamed Site"} />
                        </Box>
                    ))}
                </Collapse>

                {/* Departments Menu */}
                <Collapse in={currentMenu === "departments"} unmountOnExit>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                        <IconButton onClick={handleBack}>
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography variant="h6" fontWeight="bold" sx={{ ml: 1 }}>
                            Departments
                        </Typography>
                    </Box>
                    {departments.map((dept) => (
                        <Box key={dept.department_code} sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                            <Checkbox
                                checked={tempSelectedDepartments.some((selectedDept) => JSON.stringify(selectedDept) === JSON.stringify(dept))}
                                onChange={() => toggleSelection(dept, tempSelectedDepartments, setTempSelectedDepartments)}
                            />
                            <ListItemText primary={dept.department_name || "Unnamed Department"} />
                        </Box>
                    ))}
                </Collapse>

                {/* Apply Filters and Clear Filters */}
                {currentMenu === "main" && (
                    <Box sx={{ mt: 2 }}>
                        <Button variant="contained" fullWidth sx={{ mb: 1 }} onClick={handleApplyFilters}>
                            Apply Filters
                        </Button>
                        <Button variant="outlined" fullWidth onClick={handleClearFilters}>
                            Clear Filters
                        </Button>
                    </Box>
                )}
            </Box>
        </Slide>
    );
};

export default MobileFilterComponent;
