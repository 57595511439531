import React from "react";
import Header from "../../components/Header";
import { Outlet } from "react-router-dom";
import { Box, useTheme } from "@mui/material";
import { isMobile } from "react-device-detect";
import MobileHeader from "../../components/mobile/mobileHeader";

export const MainContainer = () => {
  const theme = useTheme();

  const renderMobileStyle = () => {
    const defaultStyle = {
      position: "relative",
      top: "65px",
      height: `calc(100vh - 65px)`,
      overflow: "hidden",
    } 
    if(isMobile){
      return {
        position: "relative",
        overflow: "hidden",
      }
    } else { 
      return defaultStyle
    }
  }

  return (
    <Box display={"flex"} flexDirection={"column"}>
      {isMobile ? <MobileHeader/>:  <Header />}
      <Box
        sx={renderMobileStyle}
      >
        <Outlet />
      </Box>
    </Box>
  );
};
