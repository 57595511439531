import React from "react";
import { Box, Button, Card, CardContent, Paper } from "@mui/material";
import { Send, X } from "lucide-react";
import DateRangePicker from "./DateRangePicker";
import TimeOffCategorySelect from "./TimeOffCategorySelect";
import DateDisplay from "./DateDisplay";
import NoteInput from "./NoteInput";
import BalanceDisplay from "./BalanceDisplay";
import { TimeOffBalance } from "../../types";

interface WebComponentProps {
    startDate: Date | null;
    setStartDate: (date: Date | null) => void;
    endDate: Date | null;
    setEndDate: (date: Date | null) => void;
    category: string;
    setCategory: (category: string) => void;
    note: string;
    setNote: (note: string) => void;
    dates: Date[];
    balances: TimeOffBalance[];
    handleSubmit: (event: React.FormEvent) => void;
}

const WebComponent: React.FC<WebComponentProps> = ({
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    category,
    setCategory,
    note,
    setNote,
    dates,
    balances,
    handleSubmit,
}) => (
    <Box display="flex" flexWrap="wrap" gap={3} m={2}>
        <Box flex="1" minWidth="0" component={Paper} elevation={1} sx={{ p: 3 }}>
            <form onSubmit={handleSubmit}>
                <Box display="flex" flexDirection="column" gap={3}>
                    <DateRangePicker startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />
                    <TimeOffCategorySelect category={category} setCategory={setCategory} />
                    <DateDisplay dates={dates} />
                    <NoteInput note={note} setNote={setNote} />
                    <Box display="flex" gap={2}>
                        <Button variant="contained" color="primary" startIcon={<Send />} type="submit">
                            Send Request
                        </Button>
                        <Button variant="outlined" color="error" startIcon={<X />}>
                            Cancel
                        </Button>
                    </Box>
                </Box>
            </form>
        </Box>
        <Box width={{ xs: "100%", md: "30%" }} component={Card}>
            <CardContent>
                <BalanceDisplay balances={balances} />
            </CardContent>
        </Box>
    </Box>
);

export default WebComponent;
