import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { DRAWERWIDTH } from "../../utils/constants";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SettingsIcon from "@mui/icons-material/Settings";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";

import ArticleIcon from "@mui/icons-material/Article";
import GroupsIcon from "@mui/icons-material/Groups";
import WebAssetIcon from "@mui/icons-material/WebAsset";
import TodayIcon from "@mui/icons-material/Today";
import { s } from "@fullcalendar/core/internal-common";
import { Avatar } from "@mui/material";
import { IUser } from "../../redux/features/user/models/user";
import { providePhpRoute } from "../../routes/phpRoutes";
import { PhpRoute } from "../../enums";

const openedMixin = (theme: Theme): CSSObject => ({
    width: DRAWERWIDTH,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `0`,
    [theme.breakpoints.up("md")]: {
        width: DRAWERWIDTH,
    },
});

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
}));

const DrawerCustomize = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    width: DRAWERWIDTH,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
}));

export const DrawerMenu = [
    {
        // path: "/Dashboard",
        path: providePhpRoute(PhpRoute.HOME),
        sidebarName: "Dashboard",
        Icon: <DashboardIcon />,
    },
    // {
    //   path: "/AccountProfile",
    //   sidebarName: "Account Profile",
    //   Icon: <AccountCircleIcon />,
    // },
    // {
    //   path: "/TimeCard",
    //   sidebarName: "TimeCard",
    //   Icon: <AccessTimeFilledIcon />,
    // },
    // {
    //   path: "/Reports",
    //   sidebarName: "Reports",
    //   Icon: <ArticleIcon />,
    // },
    // {
    //   path: "/Employees",
    //   sidebarName: "Employees",
    //   Icon: <GroupsIcon />,
    // },
    // {
    //   path: "/Export",
    //   sidebarName: "Export",
    //   Icon: <InboxIcon />,
    // },
    // {
    //   path: "/TimeClock",
    //   sidebarName: "Time Clock",
    //   Icon: <AccessTimeFilledIcon />,
    // },
    // {
    //   path: "/Sites",
    //   sidebarName: "Sites",
    //   Icon: <WebAssetIcon />,
    // },
    {
        path: "/Schedule",
        sidebarName: "Schedule",
        Icon: <TodayIcon />,
    },
    {
        path: "/time-off-request",
        sidebarName: "Request Time-off!",
        Icon: <TodayIcon />,
    },
    // {
    //   path: "/Settings",
    //   sidebarName: "Settings",
    //   Icon: <SettingsIcon />,
    // },
    // {
    //   path: "/Timesheet",
    //   sidebarName: "Timesheet",
    //   Icon: <SettingsIcon />,
    // },
    // {
    //   path: "/profilePage",
    //   sidebarName: "Profile Page",
    //   Icon: <SettingsIcon />,
    // },
    // {
    //   path:'/timecardv2',
    //   sidebarName: 'Timecard V2',
    //   Icon: <SettingsIcon />
    // }
    // {
    //   path: "/Logout",
    //   sidebarName: "Logout",
    //   Icon: <ExitToAppIcon />,
    // },
];

export default function SideBar() {
    const theme = useTheme();

    const navigate = useNavigate();

    const isSidebarOpen = useSelector((state: RootState) => state.layout.sidebar);

    const User = useSelector((state: RootState) => state.user.user) as IUser;

    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />

            <DrawerCustomize variant="permanent" anchor="left" open={isSidebarOpen}>
                <Box role="presentation" sx={{ width: "250px" }}>
                    <DrawerHeader />

                    <Divider />

                    {/* Profile Picture */}
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Avatar
                            src={User.osEmpImageName ?? "https://www.gravatar.com/avatar/?d=mp&s=100"} // Replace with your actual image URL
                            alt="Profile Image"
                            sx={{
                                m: 3,
                                border: 1,
                                borderRadius: "50%",
                                width: "100px",
                                height: "100px", // Keep the width and height same for a proper circle
                            }}
                        />
                    </Box>

                    {/* Drawer Menu List */}
                    <List>
                        {DrawerMenu.map((props, key) => {
                            const { Icon, path, sidebarName } = props;
                            return (
                                <ListItem
                                    disablePadding
                                    sx={{ display: "block" }}
                                    onClick={() => {
                                        if (path.startsWith("http")) {
                                            window.open(path, "_blank", "noopener,noreferrer");
                                            // window.location.href = path; // Redirect to external site
                                        } else {
                                            navigate(path); // Navigate internally for other items
                                        }
                                    }}
                                    key={key}
                                >
                                    <ListItemButton
                                        sx={{
                                            justifyContent: "initial",
                                            px: 3,
                                        }}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                mr: 3,
                                                justifyContent: "center",
                                            }}
                                        >
                                            {Icon}
                                        </ListItemIcon>
                                        <ListItemText primary={sidebarName} sx={{ opacity: 1, color: "#9d9d9d" }} />
                                    </ListItemButton>
                                </ListItem>
                            );
                        })}
                        <ListItem disablePadding sx={{ display: "block" }} onClick={() => window.location.href = providePhpRoute(PhpRoute.LOGOUT)}>
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: "initial",
                                    px: 3,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: 3,
                                        justifyContent: "center",
                                    }}
                                >
                                    <ExitToAppIcon />
                                </ListItemIcon>
                                <ListItemText primary="Logout" sx={{ opacity: 1, color: "#9d9d9d" }} />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Box>
            </DrawerCustomize>
        </Box>
    );
}
