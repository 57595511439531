import { createSlice } from "@reduxjs/toolkit";
import { CommonState } from "../../../types/state/common";
import { getDepartments, getSites } from "./api";

const initialState: CommonState = {
    sites: [],
    departments: []
};

export const CommonSlice = createSlice({
    name: "common",
    initialState,
    reducers: {
        clearSites: (state) => {
            state.sites = [];
        },
    },
    extraReducers: {
        // get sites
        [getSites.fulfilled.type]: (state, { payload }) => {
            if (payload.data) {
                state.sites = payload.data;
            }
        },

        [getSites.rejected.type]: (state) => {
            state.sites = [];
        },

        [getSites.pending.type]: (state) => {
            state.sites = [];
        },

        // get departments
        [getDepartments.fulfilled.type]: (state, { payload }) => {
            if (payload.data) {
                state.departments = payload.data;
            }
        },

        [getDepartments.rejected.type]: (state) => {
            state.departments = [];
        },

        [getDepartments.pending.type]: (state) => {
            state.departments = [];
        },
    },
});

export const {} = CommonSlice.actions;
export default CommonSlice.reducer;
