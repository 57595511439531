import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

interface TimeOffCategorySelectProps {
    category: string;
    setCategory: (category: string) => void;
}

const TimeOffCategorySelect: React.FC<TimeOffCategorySelectProps> = ({ category, setCategory }) => {
    return (
        <FormControl fullWidth required>
            <InputLabel>Time Off Category</InputLabel>
            <Select
                value={category}
                label="Time Off Category"
                onChange={(e) => setCategory(e.target.value)}
            >
                <MenuItem value="bereavement">Bereavement</MenuItem>
                <MenuItem value="comp">Comp/In Lieu Time</MenuItem>
                <MenuItem value="fmla">FMLA</MenuItem>
                <MenuItem value="vacation">Vacation</MenuItem>
            </Select>
        </FormControl>
    );
};

export default TimeOffCategorySelect;
