import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "@fortawesome/fontawesome-free/css/fontawesome.min.css";
import "@fortawesome/fontawesome-free/css/solid.min.css";
import "@fortawesome/fontawesome-free/css/regular.min.css";
import "@fortawesome/fontawesome-free/css/brands.min.css";
import Cookies from "js-cookie";
import { enableMapSet } from "immer";
import { TestTokenProvider, TestUser } from "./dev";
import { useEffect } from "react";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

// THIS WILL SET COOKIES FOR TOKEN FOR DEVELOPMENT ONLY
if (process.env.REACT_APP_ENV === "dev" || process.env.REACT_APP_ENV === "local") {
    const cookieValue = TestTokenProvider(TestUser.AGNI);
    Cookies.set("U_EVAL_VALUE", cookieValue);
    localStorage.setItem("U_EVAL_VALUE", cookieValue);
}

// Get token from localStorage or Cookies
// first check in localStorage, if not found, check in Cookies
let userToken = localStorage.getItem("U_EVAL_VALUE");

if (!userToken) {
    userToken = Cookies.get("U_EVAL_VALUE") || localStorage.getItem("U_EVAL_VALUE");
}


if (!userToken) {
    const redirectUrl = `https://app.webapph.com/app17/login/loginform/`;
    window.location.href = redirectUrl;
} else {
    enableMapSet();

    root.render(
        <Provider store={store}>
            <App />
        </Provider>
    );

    reportWebVitals();
}
