import { createAsyncThunk } from "@reduxjs/toolkit";
import { phpClient } from "../../../axios";
import { DepartmentMaster, PhpResponse, SiteType } from "../../../types";


export const getSites = createAsyncThunk("/sites", async (undefined, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
        const response = await phpClient.get<PhpResponse<SiteType[]>>({
            url: "/webclock/locations_list"
        });
        return response;
    } catch (error) {
        console.log("error ===>", error);
        return rejectWithValue(error);
    }
});

export const getDepartments = createAsyncThunk("/departments", async (undefined, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
        const response = await phpClient.get<PhpResponse<DepartmentMaster[]>>({
            url: "/webclock/department_list"
        });
        return response;
    } catch (error) {
        console.log("error ===>", error);
        return rejectWithValue(error);
    }
});