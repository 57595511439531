import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { DateTimeUtil } from "../../utils";

interface DateDisplayProps {
    dates: Date[];
}

const DateDisplay: React.FC<DateDisplayProps> = ({ dates }) => {
    return (
        <Box>
            {dates.map((date, index) => (
                <Typography key={index}>{DateTimeUtil.formatDate(date, "dd/MM/yyyy")}</Typography>
            ))}
        </Box>
    );
};

export default DateDisplay;
