import axios from "axios";
import Cookies from "js-cookie";
import { getApiUrl } from "../utils";

const headers = {
  "Content-Type": "application/json",
};

const API = axios.create({
  baseURL: getApiUrl(),
  headers,
});


export class HttpError extends Error {
  constructor(message?: string) {
    super(message); // 'Error' breaks prototype chain here
    this.name = "HttpError";
    Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
  }
}

API.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("U_EVAL_VALUE") || Cookies.get("U_EVAL_VALUE");

    config.headers.Authorization = `Bearer ${token}`;
    config.headers.appkey = process.env.REACT_APP_APP_KEY;

    return config;
  },
  (error) => {
    console.log('Error', error);
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  (res) => {
    const data = res;
    if (!data) {
      throw new HttpError("API Error. No data!");
    }
    return data;
  },
  (error) => {
    console.log("Error", error);
    // if (error.response?.status === 401) {
    //   window.location.href = "https://www.google.com";
    // }
  }
);

export default API;
