import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AppBar, Toolbar, IconButton, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const MobileHeader: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();

    // Do not render the header if the current location is /mobile-dashboard
    if (location.pathname === "/mobile-dashboard") {
        return null;
    }

    const handleBack = () => {
        navigate(-1); // Navigate back one step
    };

    return (
        <AppBar position="static" sx={{ backgroundColor: "transparent", borderBottom: "1px solid #e0e0e0"  }}>
            <Toolbar>
                <IconButton edge="start" onClick={handleBack} aria-label="back"
                    sx={{   
                        color: "#4d4d4d",
                    }}
                >
                    <ArrowBackIcon />
                </IconButton>
            </Toolbar>
        </AppBar>
    );
};

export default MobileHeader;
