import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const mockData = [
  { name: "Aiza", date: "12-06-2024", time: "09:19:20 AM", code: "Regular", department: "Packhouse", site: "Site A" },
  { name: "Aleksandar", date: "12-06-2024", time: "09:33:00 AM", code: "Regular", department: "House F", site: "Site B" },
  { name: "Anastasiia", date: "12-06-2024", time: "05:58:15 AM", code: "Regular", department: "Packhouse", site: "Site A" },
  { name: "Asan", date: "12-06-2024", time: "09:29:00 AM", code: "Regular", department: "Nursery", site: "Site C" },
  { name: "Altidzhe", date: "12-06-2024", time: "08:02:00 AM", code: "Regular", department: "Inspired", site: "Site B" },
  { name: "Ayshe", date: "12-06-2024", time: "08:37:10 AM", code: "Regular", department: "Packhouse", site: "Site A" },
  { name: "Ayten", date: "12-06-2024", time: "09:03:23 AM", code: "Regular", department: "Packhouse", site: "Site A" },
  { name: "Beata", date: "12-06-2024", time: "09:27:30 AM", code: "Regular", department: "Packhouse", site: "Site A" },
];

const WhoIsIn: React.FC = () => {
  // States for filters
  const [selectedSite, setSelectedSite] = useState<string>("");
  const [selectedDepartment, setSelectedDepartment] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const storeValue = localStorage.getItem('U_EVAL_VALUE');
  // Handler functions
  const handleSiteChange = (event: SelectChangeEvent<string>) => {
    setSelectedSite(event.target.value as string);
  };

  const handleDepartmentChange = (event: SelectChangeEvent<string>) => {
    setSelectedDepartment(event.target.value as string);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  // Filtered data based on selected filters
  const filteredData = mockData.filter((row) => {
    const matchesSite = selectedSite ? row.site === selectedSite : true;
    const matchesDepartment = selectedDepartment ? row.department === selectedDepartment : true;
    const matchesSearch = row.name.toLowerCase().includes(searchTerm.toLowerCase());
    return matchesSite && matchesDepartment && matchesSearch;
  });

  return (
    <Container maxWidth="lg">
      <Box display="flex" alignItems="center" justifyContent="space-between" py={2}>
        <Typography variant="h6">Who Is In</Typography>
        <Typography variant="h6">{storeValue ?? "loading value "}</Typography>
        <Button variant="outlined">Submit</Button>
      </Box>
      <Box display="flex" gap={2} mb={3} flexWrap="wrap">
        {/* Site Filter */}
        <Select
          value={selectedSite}
          onChange={handleSiteChange}
          displayEmpty
          fullWidth
          style={{ flex: "1" }}
        >
          <MenuItem value="">All Sites</MenuItem>
          <MenuItem value="Site A">Site A</MenuItem>
          <MenuItem value="Site B">Site B</MenuItem>
          <MenuItem value="Site C">Site C</MenuItem>
        </Select>

        {/* Department Filter */}
        <Select
          value={selectedDepartment}
          onChange={handleDepartmentChange}
          displayEmpty
          fullWidth
          style={{ flex: "1" }}
        >
          <MenuItem value="">Select Department</MenuItem>
          <MenuItem value="Packhouse">Packhouse</MenuItem>
          <MenuItem value="House F">House F</MenuItem>
          <MenuItem value="Nursery">Nursery</MenuItem>
          <MenuItem value="Inspired">Inspired</MenuItem>
        </Select>

        {/* Search Field */}
        <TextField
          placeholder="Search for..."
          value={searchTerm}
          onChange={handleSearchChange}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      {/* Table */}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Employee Name</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Time</TableCell>
              <TableCell>Work Code</TableCell>
              <TableCell>Department</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.date}</TableCell>
                <TableCell>{row.time}</TableCell>
                <TableCell>{row.code}</TableCell>
                <TableCell>{row.department}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default WhoIsIn;
