import React from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Box from "@mui/material/Box";

interface DateRangePickerProps {
    startDate: Date | null;
    setStartDate: (date: Date | null) => void;
    endDate: Date | null;
    setEndDate: (date: Date | null) => void;
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({ startDate, setStartDate, endDate, setEndDate }) => {
    return (
        <Box display="flex" gap={3} flexDirection={{ xs: "column", sm: "row" }}>
            <DatePicker
                label="From"
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
                slotProps={{
                    textField: { fullWidth: true, required: true },
                }}
            />
            <DatePicker
                label="To"
                value={endDate}
                onChange={(newValue) => setEndDate(newValue)}
                slotProps={{
                    textField: { fullWidth: true, required: true },
                }}
            />
        </Box>
    );
};

export default DateRangePicker;
