import React from "react";
import TextField from "@mui/material/TextField";

interface NoteInputProps {
    note: string;
    setNote: (note: string) => void;
}

const NoteInput: React.FC<NoteInputProps> = ({ note, setNote }) => {
    return (
        <TextField
            fullWidth
            multiline
            rows={4}
            label="Note"
            value={note}
            onChange={(e) => setNote(e.target.value)}
        />
    );
};

export default NoteInput;
