import React from "react";
import { Box, Typography, Divider } from "@mui/material";

interface BalanceItem {
    type: string;
    used: string;
    available?: string;
    icon: React.ReactNode;
}

interface BalanceDisplayProps {
    balances: BalanceItem[];
}

const BalanceDisplay: React.FC<BalanceDisplayProps> = ({ balances }) => {
    return (
        <Box>
            <Typography variant="h6" gutterBottom>
                Balance as of {new Date().toLocaleDateString()}
            </Typography>
            <Divider sx={{ my: 2 }} />
            {balances.map((balance, index) => (
                <Box key={index} display="flex" alignItems="flex-start" gap={2} mb={2}>
                    <Box pt={0.5}>{balance.icon}</Box>
                    <Box>
                        <Typography variant="subtitle1">{balance.type}</Typography>
                        <Typography variant="body2" color="text.secondary">
                            {balance.used}
                        </Typography>
                        {balance.available && (
                            <Typography variant="body2" color="text.secondary">
                                {balance.available}
                            </Typography>
                        )}
                    </Box>
                </Box>
            ))}
        </Box>
    );
};

export default BalanceDisplay;
