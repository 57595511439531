export enum TestUser {
    AGNI = "AGNI", // manager
    ARYA = "ARYA", // employee 1
    TEJAS = "TEJAS", // employee 2
}

export const TestTokenProvider = (user: TestUser) => {
    switch (user) {
        case TestUser.AGNI:
            return "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2FjY291bnRfaWQiOiIxNzM1MjA4MDE5IiwibG9nZ2VkaW5faWQiOiI1NDg4NSJ9.IpDBW-upBFJrekpN5gzEb0o0RT9e-qs8Tet9l2j2XHs";

        case TestUser.TEJAS:
            return "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2FjY291bnRfaWQiOiIxNzM1MjA4MDE5IiwibG9nZ2VkaW5faWQiOiI1NDg4NCJ9.0KYJnYE-zDltq0gW5389b6jrIwuoW9M-XN5I4Z3jynE";

        case TestUser.ARYA:
            return "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2FjY291bnRfaWQiOiIxNzM1MjA4MDE5IiwibG9nZ2VkaW5faWQiOiI1NDg4MyJ9.8qrz49RntEQWnelwK3Kp7J99WTt9McfreAwitbeaJy8";
    }
};
