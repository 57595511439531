import React from "react";
import {
  Box,
  MenuItem,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  Button,
} from "@mui/material";

const MobileFilter = () => {
  const [department, setDepartment] = React.useState("");
  const [workcode, setWorkcode] = React.useState("");
  const [site, setSite] = React.useState("Auto");

  const handleApplyFilters = () => {
    // Logic for applying filters
    console.log("Filters Applied", { department, workcode, site });
  };

  return (
    <Box
      sx={{
        p: 2,
        maxWidth: 400,
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Typography variant="h6" textAlign="center">
        Attendance Form
      </Typography>

      {/* Department Field */}
      <FormControl fullWidth>
        <InputLabel id="department-label">Department</InputLabel>
        <Select
          labelId="department-label"
          id="department"
          value={department}
          onChange={(e) => setDepartment(e.target.value)}
          label="Department"
        >
          <MenuItem value="HR">HR</MenuItem>
          <MenuItem value="Finance">Finance</MenuItem>
          <MenuItem value="IT">IT</MenuItem>
        </Select>
      </FormControl>

      {/* Workcode Field */}
      <FormControl fullWidth>
        <InputLabel id="workcode-label">Workcode</InputLabel>
        <Select
          labelId="workcode-label"
          id="workcode"
          value={workcode}
          onChange={(e) => setWorkcode(e.target.value)}
          label="Workcode"
        >
          <MenuItem value="W123">W123</MenuItem>
          <MenuItem value="W124">W124</MenuItem>
          <MenuItem value="W125">W125</MenuItem>
        </Select>
      </FormControl>

      {/* Site Field */}
      <TextField
        id="site"
        label="Site"
        value={site}
        disabled
        fullWidth
        InputProps={{
          readOnly: true,
        }}
      />

      {/* Apply Filters Button */}
      <Button
        variant="outlined"
        color="primary"
        fullWidth
        onClick={handleApplyFilters}
        sx={{ mt: 2 }}
      >
        Apply Filters
      </Button>
    </Box>
  );
};

export default MobileFilter;
