import { AxiosRequestConfig, AxiosResponse } from "axios";
import { AxiosClient } from "./axiosClient";
import { PhpClientPaginatedResponse, PhpMeta, PhpResponsePaginated, RequestOptions } from "../types";

export const DefaultPaginatedMeta: PhpMeta = {
    count: 0,
    page: 0,
    limit: 0,
};

class PhpClient extends AxiosClient {
    constructor() {
        super();
        this.axiosInstance.defaults.baseURL = process.env.REACT_APP_PHP_BACKEND_URL;
    }

    async getPaginated<T>({ url, data = {}, params = {}, extraHeaders = {} }: RequestOptions): Promise<PhpClientPaginatedResponse<T>> {
        try {
            const config: AxiosRequestConfig = {
                url,
                params,
                headers: {
                    ...this.axiosInstance.defaults.headers.common,
                    ...extraHeaders,
                },
                data, // Include the body in the GET request config
                method: "get", // Explicitly set the method to 'get'
            };

            const response: AxiosResponse<PhpResponsePaginated<T>> = await this.axiosInstance.request(config);
            const { data: responseData } = response;
            return {
                data: responseData.data || [],
                meta: responseData.meta || DefaultPaginatedMeta,
            } as PhpClientPaginatedResponse<T>;
        } catch (error) {
            this.handleError(error);
        }
    }

    async postPaginated<T>({ url, data = {}, extraHeaders = {} }: RequestOptions): Promise<PhpClientPaginatedResponse<T>> {
        try {
            const config: AxiosRequestConfig = {
                headers: {
                    ...this.axiosInstance.defaults.headers.common,
                    ...extraHeaders,
                },
            };

            const response: AxiosResponse<PhpResponsePaginated<T>> = await this.axiosInstance.post(url, data, config);
            const { data: responseData } = response;
            return {
                data: responseData.data || [],
                meta: responseData.meta || DefaultPaginatedMeta,
            } as PhpClientPaginatedResponse<T>; 
        } catch (error) {
            this.handleError(error);
        }
    }
}

export default new PhpClient();
