import React from "react";
import { CalendarEvent } from "../../../types";

interface MonthlyViewProps {
  events: CalendarEvent[];
  navigation: (view: "week" | "month") => void; // Navigation prop
}

const MonthlyView: React.FC<MonthlyViewProps> = ({ events, navigation }) => {
  const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  return (
    <div className="grid grid-cols-7 gap-2">
      {/* Days of the week */}
      {daysOfWeek.map((day) => (
        <div key={day} className="p-2 text-center font-medium text-gray-900">
          {day}
        </div>
      ))}
      {/* Dates */}
      {Array.from({ length: 30 }, (_, i) => i + 1).map((date) => (
        <div
          key={date}
          className="h-32 p-2 border border-gray-200 text-xs font-medium text-gray-900 flex flex-col justify-start items-start hover:bg-stone-100"
        >
          <span>{date}</span>
          {events
            .filter((event) => parseInt(event.day.split(" ")[1]) === date)
            .map((event, idx) => (
              <div
                key={idx}
                className={`rounded p-1.5 mb-1 border-l-2 border-${event.color}-600 bg-${event.color}-50 w-full`}
              >
                <p className="text-[10px] font-normal text-gray-900 truncate">
                  {event.title}
                </p>
              </div>
            ))}
        </div>
      ))}
    </div>
  );
};

export default MonthlyView;
