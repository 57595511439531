import { createSlice, createSelector } from "@reduxjs/toolkit";
// import { APP_DEFAULT_VERSION } from "../../../utils/constants";

import { IUser } from "./models/user";
import { FULFILLED, PENDING, REJECTED } from "../../constants";
// import { IShift, IAvailShifts } from './models/schedule'
import { getUserDetails, getUserDetailsMe, getUsers } from "./UserApi";
import { UserType } from "../../../types/state/user";
import { ActorProfileMaster } from "../../../types";

export const UserSlice = createSlice({
  name: "userSlice",
  initialState: {
    user: {
      id: -1,
      userAccountId: -1,
      firstName: "", // userNameFirst
      lastName: "", // userNameLast
      osEmpPrivilege: 0,
      groupEmployees: [],
      isSuperUser: 0,
      userTimeZoneId: 0,
      empPrivScheduleTool: false,
      userSettings: []
    } as ActorProfileMaster,
    users: [] as any[],
    requestState: FULFILLED,
    // userSettingsVersion: APP_DEFAULT_VERSION,
  },
  reducers: {},
  extraReducers: {
    // [isLogged.pending.type]: (state, action) => {
    //   state.requestState = PENDING;
    // },
    // [isLogged.fulfilled.type]: (state, { payload }) => {
    //   if (payload === "") {
    //     window.location.href = createCIURL(
    //       APP_DEFAULT_VERSION,
    //       "/login/loginform"
    //     );
    //   } else {
    //     if (payload === "") {
    //       //window.location.href = CI_SERVER_URL + '/login/loginform'
    //     } else {
    //       state.user = {
    //         //convert!!!
    //         ...payload,
    //         userAccountId: parseInt(payload.userAccountId),
    //         firstName: toCapital(payload.firstName),
    //         lastName: toCapital(payload.lastName),
    //       };
    //     }
    //     state.userSettingsVersion = parseInt(payload.userSettingsVersion);
    //   }
    //   state.requestState = FULFILLED;
    // },
    // [isLogged.rejected.type]: (state, action) => {
    //   state.requestState = REJECTED;
    // },
    [getUsers.pending.type]: (state, action) => {
      state.requestState = PENDING;
    },
    [getUsers.fulfilled.type]: (state, { payload }) => {
      console.log("payload log", payload);

      state.users = [...payload.data];
      state.requestState = FULFILLED;
    },
    [getUsers.rejected.type]: (state, action) => {
      state.requestState = REJECTED;
    },
    [getUserDetails.pending.type]: (state, action) => {
      state.requestState = PENDING;
    },
    [getUserDetails.fulfilled.type]: (state, { payload }) => {
      state.user = {
        ...payload,
        // isSuperUser: (payload.id || payload.osEmpAccountId) === payload.userAccountId ? 1 : 0,
      };
    },
    [getUserDetails.rejected.type]: (state, action) => {
      state.requestState = REJECTED;
    },

    // get user details me
    [getUserDetailsMe.pending.type]: (state, action) => {},
  },
});

export const getUserData = createSelector(
  (state: any) => state.user,
  (user: IUser) => user
);

export default UserSlice.reducer;
