import React, { useEffect, useState } from "react";
import { Typography, Box, Tabs, Tab } from "@mui/material";
import { faker } from "@faker-js/faker";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../redux/store";
import { fetchPaginatedEmployees, getWhoIsWorking } from "../../../redux/features/dashboard";
import { WhoIsWorking } from "../../../components/mobile/mobileDashboard";
import { getDepartments, getSites } from "../../../redux/features/common";
import { EmployeeViewType } from "../../../enums/employeeViewType";
import MobileCalender from "../calendar";

// Generate demo data for team hours
const generateTeamHours = () => ({
    REG: `${faker.number.int({ min: 6, max: 8 })}h ${faker.number.int({ min: 0, max: 59 })}m`,
    OT: `${faker.number.int({ min: 0, max: 3 })}h ${faker.number.int({ min: 0, max: 59 })}m`,
    DT: `${faker.number.int({ min: 0, max: 2 })}h ${faker.number.int({ min: 0, max: 59 })}m`,
    PTO: `${faker.number.int({ min: 0, max: 8 })}h ${faker.number.int({ min: 0, max: 59 })}m`,
});

// Demo data for both Today and Weekly
const todayHours = generateTeamHours();
const weeklyHours = generateTeamHours();

const MobileDashboard: React.FC = () => {
    const [activeTab, setActiveTab] = useState<"today" | "weekly">("today");
    const user = useSelector((state: RootState) => state.user.user);

    // Dynamic data based on tab selection
    const currentHours = activeTab === "today" ? todayHours : weeklyHours;
    const dispatch = useAppDispatch();
    const viewStates = useSelector((state: RootState) => state.dashboard.employeeViewStates);
    const { employees: whoIsWorkingEmp, isLoading: whoIsWorkingLoading } = viewStates[EmployeeViewType.WHO_IS_WORKING];

    useEffect(() => {
        dispatch(fetchPaginatedEmployees({ viewType: EmployeeViewType.WHO_IS_WORKING, data: {} }));
        dispatch(getSites());
        dispatch(getDepartments());
        console.log('user', user);
    }, []);

    return (
        <Box p={2}>
            {/* Team Hours Summary Box */}
            <Box
                sx={{
                    mb: 2,
                    p: 2,
                    borderRadius: 2,
                    border: "1px solid #ccc",
                    // boxShadow: 1,
                    backgroundColor: "white",
                }}
            >
                <Typography variant="h6" fontWeight="bold">
                    Work Hours Summary
                </Typography>

                {/* Tabs */}
                <Tabs
                    value={activeTab}
                    onChange={(_, newValue) => setActiveTab(newValue)}
                    textColor="primary"
                    indicatorColor="primary"
                    style={{ background: "white", border: "none" }}
                >
                    <Tab label="TODAY" value="today" />
                    <Tab label="WEEKLY" value="weekly" />
                </Tabs>

                {/* Hours Data */}
                <Box display="flex" justifyContent="space-between" sx={{ mt: 2 }}>
                    <Box>
                        <Typography variant="body2" color="text.secondary">
                            REG
                        </Typography>
                        <Typography variant="h6">{currentHours.REG}</Typography>
                    </Box>
                    <Box>
                        <Typography variant="body2" color="text.secondary">
                            OT
                        </Typography>
                        <Typography variant="h6">{currentHours.OT}</Typography>
                    </Box>
                    <Box>
                        <Typography variant="body2" color="text.secondary">
                            DT
                        </Typography>
                        <Typography variant="h6">{currentHours.DT}</Typography>
                    </Box>
                    <Box>
                        <Typography variant="body2" color="text.secondary">
                            PTO
                        </Typography>
                        <Typography variant="h6">{currentHours.PTO}</Typography>
                    </Box>
                </Box>
                <Typography color="primary" sx={{ mt: 2 }}>
                    Timecard &gt;
                </Typography>
            </Box>

            {/* Who is Working Box */}
            {user.isManager && <WhoIsWorking employees={whoIsWorkingEmp} isLoading={whoIsWorkingLoading} />}

            {/* Calendar */}
            <MobileCalender/>
        </Box>
    );
};

export default MobileDashboard;
