import { AccountProfile } from "../pages/AccountProfile";
import { Employees } from "../pages/Employees";
import { Export } from "../pages/Export";
import { Reports } from "../pages/Reports";
import { Settings } from "../pages/Settings";
import { Sites } from "../pages/Sites";
import {TimeCard} from "../pages/TimeCard";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { Main } from "../pages/main";
import { styled } from "@mui/material";
import { MainContainer } from "../pages/mainContainer";
import TimesheetMain from "../components/timesheet/TimesheetMain";
import { Dashboard } from "../pages/Dashboard";
import Calendar from "../components/mySchedule/Calendar";
import ShiftCard from "../components/mySchedule/ShiftCard";
import ApproveDialog from "../components/mySchedule/ApproveDialog";
import MySchedule from "../pages/MySchedule";
import { WelcomePage } from "../pages/WelcomePage";
import ProfilePage from "../pages/ProfilePage";
import TimesheetPage from "../pages/Timesheet";
import { NotificationScreen } from "../components/notification";
import NotificationDetail from "../components/notification/notificationDetail";
import TimecardV2 from "../pages/TimeCardv2";
import DetailsView from "../components/timecard/detailView";
import RecordDetailsComponent from "../components/timecard/recordDetails";
import { TimeOffRequest } from "../pages/TimeOff";
import { WhoIsWorking } from "../pages/WhoIsWorking";
import { MobileDashboard } from "../pages/mobile";
import ViewEmployeesMobile from "../components/mobile/mobileDashboard/viewEmployees";
import { MobileFilter } from "../components/mobile/mobileDashboard";

export const DrawerRoutes = [
  {
    path: "/Dashboard",
    component: WelcomePage,
  },
  {
    path: "/AccountProfile",
    component: AccountProfile,
  },
  {
    path: "/TimeCard",
    component: TimeCard,
  },
  {
    path: "/Reports",
    component: Reports,
  },
  {
    path: "/Employees",
    component: Employees,
  },
  {
    path: "/Export",
    component: Export,
  },
  {
    path: "/TimeClock",
    component: TimeCard,
  },
  {
    path: "/Sites",
    component: Sites,
  },
  {
    path: "/Schedule",
    // component: Schedule,
    component: MySchedule,
  },
  {
    path: "/Settings",
    component: Settings,
  },
  {
    path: "/Timesheet",
    component: TimesheetPage,
  },
  {
    path: "/profilePage",
    component: ProfilePage
  },
  {
    path: '/notifications',
    component: NotificationScreen
  },
  {
    path: '/notificationDetail',
    component: NotificationDetail
  },
  {
    path: '/timecardv2',
    component: TimecardV2
  },
  {
    path: '/timecardv2/details',
    component: RecordDetailsComponent
  },
  {
    path: '/time-off-request',
    component: TimeOffRequest
  }, 
  {
    path: '/who-is-working',
    component: WhoIsWorking
  }, 
  {
    path: '/mobile-dashboard',
    component: MobileDashboard
  },
  {
    path: '/m-view-employees',
    component: ViewEmployeesMobile
  }, {
    path: '/m-filter',
    component: MobileFilter
  }
] as Array<{ path: string; component: React.ElementType }>;

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="" element={<MainContainer />}>
      <Route index element={<Main />} /> 
      <Route path="/*" element={<Main />} />
    </Route>
  )
);
