import React, { useState } from "react";
import { Box, Button, Paper, Stepper, Step, StepLabel, MobileStepper } from "@mui/material";
import { Send } from "lucide-react";
import DateRangePicker from "./DateRangePicker";
import TimeOffCategorySelect from "./TimeOffCategorySelect";
import DateDisplay from "./DateDisplay";
import NoteInput from "./NoteInput";
import BalanceDisplay from "./BalanceDisplay";
import { TimeOffBalance } from "../../types";

interface MobileComponentProps {
    startDate: Date | null;
    setStartDate: (date: Date | null) => void;
    endDate: Date | null;
    setEndDate: (date: Date | null) => void;
    category: string;
    setCategory: (category: string) => void;
    note: string;
    setNote: (note: string) => void;
    dates: Date[];
    balances: TimeOffBalance[];
    handleSubmit: (event: React.FormEvent) => void;
}

const MobileComponent: React.FC<MobileComponentProps> = ({
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    category,
    setCategory,
    note,
    setNote,
    dates,
    balances,
    handleSubmit,
}) => {
    const [activeStep, setActiveStep] = useState(0);

    const handleNext = () => setActiveStep((prev) => prev + 1);
    const handleBack = () => setActiveStep((prev) => prev - 1);

    const steps = [
        {
            label: "Select Dates & Category",
            content: (
                <Box display="flex" flexDirection="column" gap={3}>
                    <DateRangePicker startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />
                    <TimeOffCategorySelect category={category} setCategory={setCategory} />
                </Box>
            ),
        },
        {
            label: "Selected Dates",
            content: <DateDisplay dates={dates} />,
        },
        {
            label: "Add Note",
            content: <NoteInput note={note} setNote={setNote} />,
        },
        {
            label: "Balance & Submit",
            content: (
                <Box>
                    <BalanceDisplay balances={balances} />
                    <Button variant="contained" color="primary" startIcon={<Send />} onClick={handleSubmit} fullWidth>
                        Send Request
                    </Button>
                </Box>
            ),
        },
    ];

    return (
        <Box>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((step, index) => (
                    <Step key={index}>
                        <StepLabel>{step.label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <Paper elevation={1} sx={{ p: 3 }}>
                {steps[activeStep].content}
            </Paper>
            <MobileStepper
                variant="dots"
                steps={steps.length}
                position="static"
                activeStep={activeStep}
                nextButton={
                    <Button size="small" onClick={handleNext} disabled={activeStep === steps.length - 1}>
                        Next
                    </Button>
                }
                backButton={
                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                        Back
                    </Button>
                }
            />
        </Box>
    );
};

export default MobileComponent;
