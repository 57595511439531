import React from "react";
import { CalendarEvent } from "../../../types";

interface WeeklyViewProps {
  events: CalendarEvent[];
  navigation: (view: "week" | "month") => void; // Navigation prop
}

const WeeklyView: React.FC<WeeklyViewProps> = ({ events, navigation }) => {
  const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  return (
    <div className="flex flex-col border-t border-gray-200 h-96 overflow-y-auto">
      {daysOfWeek.map((day) => {
        const dayEvents = events.filter((event) => {
          const eventDate = new Date(event.day + " 2024").toLocaleString("en-us", {
            weekday: "short",
          });
          return eventDate === day;
        });

        return (
          <div key={day} className="flex flex-col border-b border-gray-200 p-4">
            <h3 className="text-lg font-medium text-gray-900">{day}</h3>
            {dayEvents.length > 0 ? (
              dayEvents.map((event, idx) => (
                <div
                  key={idx}
                  className={`rounded p-2 mb-2 border-l-4 border-${event.color}-600 bg-${event.color}-50`}
                >
                  <p className="text-sm font-semibold text-gray-900">{event.title}</p>
                  <p className="text-sm text-gray-600">{event.time}</p>
                </div>
              ))
            ) : (
              <p className="text-sm text-gray-500">No plans today!</p>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default WeeklyView;
