import { format, parse, addDays, differenceInDays, isBefore, isAfter, isSameDay } from "date-fns";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

dayjs.extend(utc);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export class DateTimeUtil {
    static DEFAULT_DATE_FORMAT = "dd/MM/yyyy";
    static DEFAULT_DATETIME_FORMAT = "yyyy-MM-dd HH:mm:ss";

    // Formatting dates using date-fns
    static formatDate(date: Date | string | null | undefined, dateFormat: string = "dd/MM/yyyy"): string {
        if (!date) return "Invalid date";
        return format(new Date(date), dateFormat);
    }

    // Parsing dates using date-fns
    static parseDate(
        dateString: string | null | undefined,
        dateFormat: string = DateTimeUtil.DEFAULT_DATETIME_FORMAT
    ): Date {
        if (!dateString) return new Date(NaN);
        return parse(dateString, dateFormat, new Date());
    }

    // Adding days using date-fns
    static addDaysToDate(date: Date | string | null | undefined, days: number): Date {
        if (!date) return new Date(NaN);
        return addDays(new Date(date), days);
    }

    // Difference in days using date-fns
    static differenceInDays(
        dateLeft: Date | string | null | undefined,
        dateRight: Date | string | null | undefined
    ): number {
        if (!dateLeft || !dateRight) return NaN;
        return differenceInDays(new Date(dateLeft), new Date(dateRight));
    }

    static isSameDayWithDateFns(
        dateLeft: Date | string | null | undefined,
        dateRight: Date | string | null | undefined
    ): boolean {
        if (!dateLeft || !dateRight) return false;
        return isSameDay(new Date(dateLeft), new Date(dateRight));
    }

    // Check if two dates are the same day using dayjs
    static isSameDayWithDayjs(
        dateLeft: Date | string | null | undefined,
        dateRight: Date | string | null | undefined
    ): boolean {
        if (!dateLeft || !dateRight) return false;
        return dayjs.utc(dateLeft).isSame(dayjs.utc(dateRight), "day");
    }

    // Check if a date is before another date using date-fns
    static isBefore(date: Date | string | null | undefined, dateToCompare: Date | string | null | undefined): boolean {
        if (!date || !dateToCompare) return false;
        return isBefore(new Date(date), new Date(dateToCompare));
    }

    // Check if a date is after another date using date-fns
    static isAfter(date: Date | string | null | undefined, dateToCompare: Date | string | null | undefined): boolean {
        if (!date || !dateToCompare) return false;
        return isAfter(new Date(date), new Date(dateToCompare));
    }

    // Formatting dates using dayjs
    static formatWithDayjs(
        date: Date | string | null | undefined,
        dateFormat: string = DateTimeUtil.DEFAULT_DATETIME_FORMAT
    ): string {
        if (!date) return "Invalid date";
        return dayjs(date).format(dateFormat);
    }

    // Parsing dates using dayjs
    static parseWithDayjs(dateString: string | null | undefined): dayjs.Dayjs {
        return dateString ? dayjs(dateString) : dayjs(null);
    }

    // Adding days using dayjs
    static addDaysWithDayjs(date: Date | string | null | undefined, days: number): dayjs.Dayjs {
        return date ? dayjs(date).add(days, "day") : dayjs(null);
    }

    // Difference in days using dayjs
    static differenceInDaysWithDayjs(
        dateLeft: Date | string | null | undefined,
        dateRight: Date | string | null | undefined
    ): number {
        if (!dateLeft || !dateRight) return NaN;
        return dayjs(dateLeft).diff(dayjs(dateRight), "day");
    }

    // Check if a date is before another date using dayjs
    static isBeforeWithDayjs(
        date: Date | string | null | undefined,
        dateToCompare: Date | string | null | undefined
    ): boolean {
        if (!date || !dateToCompare) return false;
        return dayjs(date).isBefore(dayjs(dateToCompare));
    }

    // Check if a date is after another date using dayjs
    static isAfterWithDayjs(
        date: Date | string | null | undefined,
        dateToCompare: Date | string | null | undefined
    ): boolean {
        if (!date || !dateToCompare) return false;
        return dayjs.utc(date).isAfter(dayjs.utc(dateToCompare));
    }

    // Format date/time range
    static formatDateTimeRange(
        startDateTime: Date | string | null | undefined,
        endDateTime: Date | string | null | undefined,
        invalidMessage: string = "Invalid date",
        includeDate: boolean = true
    ): string {
        const start = dayjs(startDateTime, DateTimeUtil.DEFAULT_DATETIME_FORMAT);
        const end = dayjs(endDateTime, DateTimeUtil.DEFAULT_DATETIME_FORMAT);

        let formattedStart;
        if (includeDate) {
            formattedStart = start.isValid() ? start.format("MMM D h:mmA") : invalidMessage;
        } else {
            formattedStart = start.isValid() ? start.format("h:mmA") : invalidMessage;
        }

        const formattedEnd = end.isValid() ? end.format("h:mmA") : invalidMessage;

        return `${formattedStart} - ${formattedEnd}`;
    }

    static formatDateTimeRangeUtc(
        startDateTime: Date | string | null | undefined,
        endDateTime: Date | string | null | undefined,
        invalidMessage: string = "Invalid date",
        includeDate: boolean = true
    ): string {
        const start = dayjs.utc(startDateTime, DateTimeUtil.DEFAULT_DATETIME_FORMAT);
        const end = dayjs.utc(endDateTime, DateTimeUtil.DEFAULT_DATETIME_FORMAT);

        let formattedStart;
        if (includeDate) {
            formattedStart = start.isValid() ? start.format("MMM D h:mmA") : invalidMessage;
        } else {
            formattedStart = start.isValid() ? start.format("h:mmA") : invalidMessage;
        }

        const formattedEnd = end.isValid() ? end.format("h:mmA") : invalidMessage;

        return `${formattedStart} - ${formattedEnd}`;
    }

    // Calculate duration between two dates
    static getDuration(start?: string | null, end?: string | null): string {
        if (!start || !end) return "0h 0m";
        const startTime = dayjs(start);
        const endTime = dayjs(end);
        if (!startTime.isValid() || !endTime.isValid()) return "0h 0m";
        const duration = endTime.diff(startTime, "minute");
        const hours = Math.floor(duration / 60);
        const minutes = duration % 60;
        return `${hours}h ${minutes}m`;
    }

    // Get formatted date string like "Aug 2"
    static getFormattedDate(dateString: string | null | undefined, invalidMessage: string = "Invalid date"): string {
        if (!dateString) return invalidMessage;
        const date = dayjs(dateString, DateTimeUtil.DEFAULT_DATETIME_FORMAT);
        return date.isValid() ? date.format("MMM D") : invalidMessage;
    }

    static getFormattedTime(timeString: string | null | undefined, invalidMessage: string = "Invalid time"): string {
        if (!timeString) return invalidMessage;
        const time = dayjs(timeString, DateTimeUtil.DEFAULT_DATETIME_FORMAT);
        return time.isValid() ? time.format("h:mmA") : invalidMessage;
    }

    static getFormattedTimeUtc(timeString: string | null | undefined, invalidMessage: string = "Invalid time"): string {
        if (!timeString) return invalidMessage;
        const time = dayjs(timeString, DateTimeUtil.DEFAULT_DATETIME_FORMAT).utc();
        return time.isValid() ? time.format("h:mmA") : invalidMessage;
    }

    static formatDateToMySQL(date: Date): string {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
        const hours = date.getHours().toString().padStart(2, "0");
        const minutes = date.getMinutes().toString().padStart(2, "0");
        const seconds = date.getSeconds().toString().padStart(2, "0");

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    static formatDayjsToMySQL(date: dayjs.Dayjs): string {
        return date.format("YYYY-MM-DD HH:mm:ss");
    }

    /***
     * Dayjs specific
     */

    // Format a date using dayjs in UTC
    static FormatWithDayjsUtc(
        date: Date | string | null | undefined,
        dateFormat: string = DateTimeUtil.DEFAULT_DATETIME_FORMAT
    ): string {
        if (!date) return "Invalid date";
        return dayjs.utc(date).format(dateFormat);
    }

    // Parse a date using dayjs in UTC
    static ParseWithDayjsUtc(dateString: string | null | undefined): dayjs.Dayjs {
        return dateString ? dayjs.utc(dateString) : dayjs(null);
    }

    // Add days to a date using dayjs in UTC
    static AddDaysWithDayjsUtc(date: Date | string | null | undefined, days: number): dayjs.Dayjs {
        return date ? dayjs.utc(date).add(days, "day") : dayjs(null);
    }

    // Difference in days using dayjs in UTC
    static DifferenceInDaysWithDayjsUtc(
        dateLeft: Date | string | null | undefined,
        dateRight: Date | string | null | undefined
    ): number {
        if (!dateLeft || !dateRight) return NaN;
        return dayjs.utc(dateLeft).diff(dayjs.utc(dateRight), "day");
    }

    // Check if a date is before another date using dayjs in UTC
    static IsBeforeWithDayjsUtc(
        date: Date | string | null | undefined,
        dateToCompare: Date | string | null | undefined
    ): boolean {
        if (!date || !dateToCompare) return false;
        return dayjs.utc(date).isBefore(dayjs.utc(dateToCompare));
    }

    // Check if a date is after another date using dayjs in UTC
    static IsAfterWithDayjsUtc(
        date: Date | string | null | undefined,
        dateToCompare: Date | string | null | undefined
    ): boolean {
        if (!date || !dateToCompare) return false;
        return dayjs.utc(date).isAfter(dayjs.utc(dateToCompare));
    }

    // Check if two dates are the same day using dayjs in UTC
    static IsSameDayWithDayjsUtc(
        dateLeft: Date | string | null | undefined,
        dateRight: Date | string | null | undefined
    ): boolean {
        if (!dateLeft || !dateRight) return false;
        return dayjs.utc(dateLeft).isSame(dayjs.utc(dateRight), "day");
    }

    /**
     * Generates an array of dates between a specified start and end date.
     * Optionally includes or excludes the start and end dates and can ignore the time component.
     *
     * @param start - The start date of the range.
     * @param end - The end date of the range.
     * @param includeBounds - Boolean flag to include or exclude the start and end dates in the result. Defaults to true (includes bounds).
     * @param ignoreTime - Boolean flag to reset the time of each date to midnight (00:00:00). Defaults to true.
     * @returns Array of Date objects representing each day in the range.
     */
    static GetDatesBetween(start: Date, end: Date, includeBounds: boolean = true, ignoreTime: boolean = true): Date[] {
        const dates = []; // Array to hold each date in the range
        const currentDate = new Date(start); // Copy of start date to iterate through the range

        // Reset time to midnight if ignoreTime is true
        if (ignoreTime) {
            currentDate.setHours(0, 0, 0, 0);
            end.setHours(0, 0, 0, 0);
        }

        // Adjust starting point if bounds are not included
        if (!includeBounds) {
            currentDate.setDate(currentDate.getDate() + 1); // Move to the day after the start date
        }

        // Loop through dates until reaching or including the end date based on includeBounds
        while (currentDate <= end) {
            dates.push(new Date(currentDate)); // Add the current date to the array
            currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
        }

        // If bounds are excluded, ensure the end date itself is not included in the result
        if (!includeBounds && dates[dates.length - 1].getTime() === end.getTime()) {
            dates.pop(); // Remove the end date if it was included
        }

        return dates; // Return the array of dates within the specified range
    }
}

export default DateTimeUtil;
