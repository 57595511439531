import { createAsyncThunk } from "@reduxjs/toolkit";
import { phpClient } from "../../../axios";
import { ActorProfileMaster } from "../../../types";
import { EmployeeViewType } from "../../../enums/employeeViewType";

export const getWhoIsWorking = createAsyncThunk(
    "/dashboard/who-is-working",
    async (data, thunkAPI) => {
        const { rejectWithValue } = thunkAPI;
        try {
            const response = await phpClient.postPaginated<ActorProfileMaster[]>({
                url: "/attendance/online",
                data
            });
            return response;
        } catch (error) {
            console.error("Error in getWhoIsWorking:", error);
            return rejectWithValue(error);
        }
    }
);

export const fetchPaginatedEmployees = createAsyncThunk(
    "dashboard/fetchPaginatedEmployees",
    async ({ viewType, data }: { viewType: EmployeeViewType; data?: any }, thunkAPI) => {
        const { dispatch, rejectWithValue } = thunkAPI;

        // Map of viewTypes to corresponding thunks
        const handlerMap: Record<EmployeeViewType, any> = {
            WHO_IS_WORKING: getWhoIsWorking,
            // Add more handlers as needed
        };

        const handler = handlerMap[viewType];

        if (!handler) {
            console.error(`No handler found for viewType: ${viewType}`);
            return rejectWithValue(`Unsupported viewType: ${viewType}`);
        }

        try {
            const resultAction = await dispatch(handler(data)); // Dispatch the thunk

            // Check if the action was fulfilled and return the payload
            if (resultAction.meta.requestStatus === "fulfilled") {
                return { viewType, data: resultAction.payload };
            } else {
                throw resultAction.payload || new Error("Unhandled rejection");
            }
        } catch (error) {
            console.error(`Error fetching data for viewType: ${viewType}`, error);
            return rejectWithValue(error);
        }
    }
);
