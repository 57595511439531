import { createSlice } from "@reduxjs/toolkit";
import { ActorProfileMaster, DashboardState } from "../../../types";
import { fetchPaginatedEmployees, getWhoIsWorking } from "./api";
import { phpClient } from "../../../axios";
import { DefaultPaginatedMeta } from "../../../axios/phpClient";
import { co } from "@fullcalendar/core/internal-common";
import { EmployeeViewType } from "../../../enums/employeeViewType";

const initialState: DashboardState = {
    viewAllEmployees: [],
    employeeViewType: EmployeeViewType.WHO_IS_WORKING,
    employeeViewStates: {
        [EmployeeViewType.WHO_IS_WORKING]: {
            isLoading: false,
            employees: [],
            meta: DefaultPaginatedMeta,
            filtersApplied: false,
        },

        // [EmployeeViewType.DEFAULT]: {
        //     isLoading: false,
        //     employees: [],
        //     meta: DefaultPaginatedMeta,
        // },
    },
    whoIsWorkingState: {
        isLoading: false,
        requestStatus: "FULFILLED",
        employees: [],
        meta: DefaultPaginatedMeta,
    },
};

export const DashboardSlice = createSlice({
    name: "dashboard",
    initialState: initialState,
    reducers: {
        setViewAllEmployees: (state, action) => {
            console.log("action.payload ====>", action.payload);
            const { viewType, employees } = action.payload;
            state.viewAllEmployees = employees;
            state.employeeViewType = viewType;
        },

        clearViewAllEmployees: (state) => {
            state.viewAllEmployees = [];
        },

        setFiltersApplied: (state, action) => {
            const { viewType, filtersApplied } = action.payload;
            state.employeeViewStates[viewType].filtersApplied = filtersApplied;
        }
    },
    extraReducers: {
        [fetchPaginatedEmployees.pending.type]: (state, action) => {
            const viewType = action.meta.arg.viewType;
            state.employeeViewStates[viewType].isLoading = true;
        },

        [fetchPaginatedEmployees.fulfilled.type]: (state, action) => {
            const { viewType, data } = action.payload;
            console.log("action.payload ====>", action.payload);
            const filtersApplied = state.employeeViewStates[viewType].filtersApplied;

            if(filtersApplied) {
                state.employeeViewStates[viewType].employees = [];
            }  

            // Ensure unique employees by filtering duplicates based on `os_emp_account_id`
            const existingIds = new Set(state.employeeViewStates[viewType].employees.map((emp) => emp.employee_id));
            console.log("existingIds ====>", existingIds);
            const newEmployees = data.data.filter((emp: ActorProfileMaster) => !existingIds.has(emp.employee_id));
            console.log("newEmployees ====>", newEmployees);

            state.employeeViewStates[viewType].employees = [
                ...state.employeeViewStates[viewType].employees,
                ...newEmployees, // Only add unique employees
            ];
            state.employeeViewStates[viewType].meta = data.meta;
            state.employeeViewStates[viewType].isLoading = false;

            // Update viewAllEmployees if the current viewType matches
            if (state.employeeViewType === viewType) {
                state.viewAllEmployees = state.employeeViewStates[viewType].employees;
            }
        },

        [fetchPaginatedEmployees.rejected.type]: (state, action) => {
            const viewType = action.meta.arg.viewType;
            state.employeeViewStates[viewType].isLoading = false;
        },

        /** get who is working */
        // [getWhoIsWorking.pending.type]: (state) => {
        //     state.whoIsWorkingState.isLoading = true;
        //     state.employeeViewStates[EmployeeViewType.WHO_IS_WORKING].isLoading = true;
        // },

        // [getWhoIsWorking.fulfilled.type]: (state, { payload }) => {
        //     const { data, meta } = payload;
        //     console.log("data ====>", data);
        //     console.log("meta ====>", meta);
        //     // Append new data to the existing employees list
        //     state.whoIsWorkingState.employees = [...(state.whoIsWorkingState.employees || []), ...data];

        //     // Update meta (pagination details)
        //     state.whoIsWorkingState.meta = meta;

        //     // Sync with viewAllEmployees for reusability
        //     state.viewAllEmployees = state.whoIsWorkingState.employees;

        //     state.whoIsWorkingState.isLoading = false; // Reset loading state
        //     state.whoIsWorkingState.requestStatus = "FULFILLED";

        //     // set views state
        //     state.employeeViewStates[EmployeeViewType.WHO_IS_WORKING].isLoading = false;
        //     state.employeeViewStates[EmployeeViewType.WHO_IS_WORKING].employees = state.whoIsWorkingState.employees;
        //     state.employeeViewStates[EmployeeViewType.WHO_IS_WORKING].meta = state.whoIsWorkingState.meta || DefaultPaginatedMeta;
        // },

        // [getWhoIsWorking.rejected.type]: (state) => {
        //     state.whoIsWorkingState.isLoading = false;
        //     state.whoIsWorkingState.requestStatus = "REJECTED";

        //     state.employeeViewStates[EmployeeViewType.WHO_IS_WORKING].isLoading = false;
        // },
    },
});

export const { setViewAllEmployees, clearViewAllEmployees, setFiltersApplied } = DashboardSlice.actions;
export default DashboardSlice.reducer;
