import React, { useEffect, useState } from "react";
import { Card, CardContent, Typography, TextField, Avatar, Box, Grid, Button, CircularProgress, Popover } from "@mui/material";
import {FilterAlt as FilterAltIcon, Tune} from "@mui/icons-material";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../redux/store";
import { fetchPaginatedEmployees } from "../../../redux/features/dashboard";
import MobileFilterComponent from "./filterComponent";
import { DepartmentMaster, SiteType } from "../../../types";
import { setFiltersApplied } from "../../../redux/features/dashboard/slice";
import { EmployeeViewType } from "../../../enums/employeeViewType";
import { DateTimeUtil } from "../../../utils";

const ViewEmployees: React.FC = () => {
    const title = {
        [EmployeeViewType.WHO_IS_WORKING]: "Who is Working",
    }

    const dispatch = useAppDispatch();
    const viewType = useSelector((state: RootState) => state.dashboard.employeeViewType);
    const currentState = useSelector((state: RootState) => state.dashboard.employeeViewStates[viewType]);
    // const employees = useSelector((state: RootState) => state.dashboard.);
    const { sites, departments } = useSelector((state: RootState) => state.common);

    const [page, setPage] = useState(1);

    // Filters
    const [searchQuery, setSearchQuery] = useState("");
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [selectedSites, setSelectedSites] = useState<SiteType[]>([]);
    const [selectedDepartments, setSelectedDepartments] = useState<DepartmentMaster[]>([]);

    useEffect(() => {
        if ((currentState.meta.page || 0) < Math.ceil((currentState.meta.count || 0) / (currentState.meta.limit || 0))) {
            dispatch(
                fetchPaginatedEmployees({
                    viewType,
                    data: {
                        DEPARTMENT_CODE: selectedDepartments.map((dept) => dept.department_code),
                        USERSITES: selectedSites.map((site) => site.us_user_site_profile_id),
                    },
                })
            );
        }
    }, [dispatch, viewType, page, currentState.meta.limit]);

    const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - scrollTop === clientHeight && !currentState.isLoading) {
            setPage((prevPage) => prevPage + 1);
        }
    };

    const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement> | React.TouchEvent<HTMLButtonElement>) => {
        if (anchorEl) {
            setAnchorEl(null); // Close popup if already open
        } else {
            setAnchorEl(event.currentTarget); // Open popup
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const applyFilters = (selectedSiteObjects: SiteType[], selectedDepartmentObjects: DepartmentMaster[]) => {
        setSelectedSites(selectedSiteObjects);
        setSelectedDepartments(selectedDepartmentObjects);
        dispatch(setFiltersApplied({ viewType, filtersApplied: true }));
        handleClose();
    };

    useEffect(() => {
        dispatch(
            fetchPaginatedEmployees({
                viewType,
                data: {
                    DEPARTMENT_CODE: selectedDepartments.map((dept) => dept.department_code),
                    USERSITES: selectedSites.map((site) => site.us_user_site_profile_id),
                },
            })
        );
    }, [selectedSites, selectedDepartments]);

    const filteredEmployees = currentState.employees?.filter((employee) => {
        const matchesSearch = searchQuery
            ? employee.employee_name?.toLowerCase().includes(searchQuery.toLowerCase())
            : true;

        const matchesSite = selectedSites.length === 0
            || selectedSites.some((site) => site.us_user_site_profile_id === employee.default_site_id);

        return matchesSearch && matchesSite;
    });
    return (
        <Card>
            <CardContent>
                <Typography variant="h6" fontWeight="bold" sx={{ mb: 2 }}>
                    {title[viewType]}
                </Typography>

                {/* Search and Filter */}
                <Box display="flex" alignItems="center" gap={2} sx={{ mb: 3 }}>
                    <TextField variant="outlined" placeholder="Search by employee name" fullWidth value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
                    <Tune fontSize="large" onClick={handleFilterClick as React.MouseEventHandler} />
                </Box>

                {/* Popover for Filters */}
                <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                >
                    <Box sx={{ p: 2, minWidth: 300 }}>
                        <MobileFilterComponent
                            sites={sites}
                            departments={departments}
                            applyFilters={applyFilters}
                            clearFilters={() => {
                                setSelectedSites([]);
                                setSelectedDepartments([]);
                                dispatch(setFiltersApplied({ viewType, filtersApplied: false }));
                            }}
                            onClose={handleClose}
                        />
                    </Box>
                </Popover>

                {/* Employee List */}
                <Box onScroll={handleScroll} sx={{ height: "80vh", overflowY: "auto" }}>
                    <Grid container spacing={2}>
                        {filteredEmployees && filteredEmployees.length > 0 ? (
                            filteredEmployees.map((employee) => (
                                <Grid item xs={12} sm={6} md={4} key={employee.os_emp_account_id}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            p: 2,
                                            border: "1px solid #eee",
                                            borderRadius: "8px",
                                        }}
                                    >
                                        <Avatar src={employee.profile_image} alt={employee.employee_name || "No Image"} sx={{ width: 60, height: 60, mr: 2 }} />
                                        <Box>
                                            <Typography variant="body1" fontWeight="bold">
                                                {employee.employee_name || "Unknown"}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                In: {DateTimeUtil.FormatWithDayjsUtc(employee.start_datetime) || "N/A"}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            ))
                        ) : (
                            <Box sx={{ textAlign: "center", width: "100%", mt: 2 }}>
                                <Typography variant="body1" color="text.secondary">
                                    No employees found.
                                </Typography>
                            </Box>
                        )}
                    </Grid>
                    {currentState.isLoading && (
                        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                            <CircularProgress />
                        </Box>
                    )}
                </Box>
            </CardContent>
        </Card>
    );
};

export default ViewEmployees;
