import React, { useState, useEffect } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { useMediaQuery } from "@mui/material";
import { Calendar, Clock } from "lucide-react";
import { DateTimeUtil } from "../../utils"; // Assuming this utility provides date-related functions
import { TimeOffBalance } from "../../types";
import { MobileComponent, WebComponent } from "../../components/timeOffRequest";

const MainComponent: React.FC = () => {
    const isMobile = useMediaQuery("(max-width:600px)");
    
    // State management for form fields
    const [startDate, setStartDate] = useState<Date | null>(new Date());
    const [endDate, setEndDate] = useState<Date | null>(new Date());
    const [category, setCategory] = useState<string>("bereavement");
    const [note, setNote] = useState<string>("");
    const [dates, setDates] = useState<Date[]>([]);

    useEffect(() => {
        if (startDate && endDate) {
            const datesBetween = DateTimeUtil.GetDatesBetween(startDate, endDate);
            setDates(datesBetween);
        }
    }, [startDate, endDate]);

    // Time-off balance data
    const balances: TimeOffBalance[] = [
        { type: "Bereavement", used: "0 days used (YTD)", icon: <Calendar className="text-green-600" /> },
        { type: "Comp/In Lieu Time", used: "0 hours used (YTD)", icon: <Clock className="text-blue-600" /> },
        { type: "FMLA", used: "0 hours used (YTD)", icon: <Calendar className="text-purple-600" /> },
        { type: "Vacation", used: "0 hours used (YTD)", available: "160 hours available", icon: <Calendar className="text-orange-600" /> },
    ];

    // Form submission handler
    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        console.log({ startDate, endDate, category, note });
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            {isMobile ? (
                <MobileComponent
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    category={category}
                    setCategory={setCategory}
                    note={note}
                    setNote={setNote}
                    dates={dates}
                    balances={balances}
                    handleSubmit={handleSubmit}
                />
            ) : (
                <WebComponent
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    category={category}
                    setCategory={setCategory}
                    note={note}
                    setNote={setNote}
                    dates={dates}
                    balances={balances}
                    handleSubmit={handleSubmit}
                />
            )}
        </LocalizationProvider>
    );
};

export default MainComponent;
