import React from "react";
import { Typography, Avatar, Box, Tooltip, CircularProgress } from "@mui/material";
import { ArrowForwardIos } from "@mui/icons-material";
import { ActorProfileMaster } from "../../../types";
import { useNavigate } from "react-router";

interface WhoIsWorkingProps {
    isLoading?: boolean; // Mark optional for flexibility
    employees?: ActorProfileMaster[]; // Mark optional for flexibility
}

const WhoIsWorking: React.FC<WhoIsWorkingProps> = ({ isLoading = false, employees = [] }) => {
    const navigate = useNavigate();

    const handleViewAll = () => {
        navigate("/m-view-employees");
    };

    return (
        <Box
            sx={{
                p: 2,
                borderRadius: 2,
                border: "1px solid #ccc",
                backgroundColor: "white",
                mb: 2,
            }}
        >
            <Typography variant="h6" fontWeight="bold">
                Who is working
            </Typography>
            {isLoading ? (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: 100,
                    }}
                >
                    <CircularProgress />
                </Box>
            ) : (
                <Box
                    sx={{
                        mt: 1,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1, // spacing between items
                        }}
                    >
                        {employees.length > 0 ? (
                            employees.slice(0, 5).map((worker, index) => (
                                <Tooltip
                                    title={worker.employee_name || "No Name"}
                                    key={worker.os_emp_account_id || index}
                                >
                                    <Avatar
                                        alt={worker.employee_name || "Unknown"}
                                        src={worker.profile_image}
                                        sx={{ width: 60, height: 60 }}
                                    />
                                </Tooltip>
                            ))
                        ) : (
                            <Typography variant="body2" color="text.secondary">
                                No employees are currently working.
                            </Typography>
                        )}
                    </Box>
                    {employees.length > 0 && (
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column",
                                cursor: "pointer",
                            }}
                            onClick={handleViewAll}
                        >
                            <ArrowForwardIos color="primary" />
                            <Typography color="primary">View All</Typography>
                        </Box>
                    )}
                </Box>
            )}
        </Box>
    );
};

export default WhoIsWorking;
