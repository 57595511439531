import React, { useState } from "react";
import { WeeklyView, MonthlyView } from "../../../components/mobile";
import { CalendarEvent } from "../../../types";

const MobileCalendar: React.FC = () => {
    // State to toggle between 'week' and 'month' views
    const [view, setView] = useState<"week" | "month">("week");

    // Sample events data
    const events: CalendarEvent[] = [
        { title: "Pickup the grandmother", time: "06:00 - 07:30", day: "Jan 9", color: "purple" },
        { title: "Workout and Yoga Session", time: "06:00 - 07:55", day: "Jan 10", color: "green" },
        { title: "Project Task Review", time: "08:00 - 08:25", day: "Jan 10", color: "blue" },
        { title: "Breakfast with Dhruv Patel", time: "08:00 - 09:00", day: "Jan 8", color: "yellow" },
        { title: "Dancing Zumba class", time: "09:30 - 10:00", day: "Jan 8", color: "green" },
        { title: "Doctor’s Appointment", time: "09:00 - 10:45", day: "Jan 12", color: "purple" },
        { title: "Daily Standup Meeting", time: "10:00 - 11:00", day: "Jan 7", color: "blue" },
        { title: "School Friend’s Birthday", time: "10:00 - 11:45", day: "Jan 7", color: "yellow" },
        { title: "Meeting with Project Manager", time: "11:00 - 12:30", day: "Jan 9", color: "blue" },
    ];

    // Handler for navigation (to demonstrate the navigation prop)
    const handleNavigation = (view: "week" | "month") => {
        setView(view);
    };

    return (
        <section className="relative bg-stone-50 py-6">
            <div className="w-full max-w-7xl mx-auto px-4 overflow-x-auto">
                {/* Header section */}
                <div className="flex flex-col md:flex-row items-center justify-between mb-5">
                    <h6 className="text-lg font-semibold text-gray-900">Today, January 2024</h6>
                    {/* View toggle buttons */}
                    <div className="flex items-center gap-2 mt-4 md:mt-0">
                        <button
                            className={`py-2 px-4 text-sm font-medium rounded-lg transition-all duration-300 ${
                                view === "week" ? "bg-indigo-600 text-white" : "bg-gray-100 text-gray-700 hover:bg-indigo-600 hover:text-white"
                            }`}
                            onClick={() => handleNavigation("week")}
                        >
                            Week
                        </button>
                        <button
                            className={`py-2 px-4 text-sm font-medium rounded-lg transition-all duration-300 ${
                                view === "month" ? "bg-indigo-600 text-white" : "bg-gray-100 text-gray-700 hover:bg-indigo-600 hover:text-white"
                            }`}
                            onClick={() => handleNavigation("month")}
                        >
                            Month
                        </button>
                    </div>
                </div>

                {/* Render views based on current selection */}
                {view === "week" ? <WeeklyView events={events} navigation={handleNavigation} /> : <MonthlyView events={events} navigation={handleNavigation} />}
            </div>
        </section>
    );
};

export default MobileCalendar;
